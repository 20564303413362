import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MessageService } from "src/app/shared/services/message.service";
import { ToolService } from "src/app/tools/tool.service";

@Component({
  selector: "customFileInput",
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.css"],
})
export class FileInputComponent {
  @Input() value!: string;
  @Input() tool!: string;
  @Input() fieldName!: string;
  @Input() accept = "";
  @Output() valueChange = new EventEmitter<string>();
  isLoading: boolean = false;

  constructor(private toolService: ToolService, private messageService: MessageService) {}

  onFileSelected(event: any) {
    console.log("file selcted" + this.tool + this.value);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
  
      // Check the MIME type of the file
      const fileType = file.type;
  
      // If the file is not an image (MIME type does not start with 'image/')
      if (this.accept.includes('image/') && !fileType.startsWith('image/')) {
        this.messageService.showError('Please select an image file.');
        
        // Clear the input to block the invalid file
        (document.getElementById('localFile') as HTMLInputElement).value = ''; 
      } 
      else if (this.accept.includes('video/') && !fileType.startsWith('video/')) {
        this.messageService.showError('Please select a video file.');
        
        // Clear the input to block the invalid file
        (document.getElementById('localFile') as HTMLInputElement).value = ''; 
      }
      else {
        if (file) {
          this.isLoading = true;
          const formData = new FormData();
          formData.append("brandFile", file);
    
          this.toolService.uploadFile(formData, this.tool, this.fieldName).subscribe(
            (data) => {
              if (data && data.data) {
                // this.value =
                //   "https://test.nlp-tools.app/llmToolsJavaApi/tools/" + data.data;
                this.value = data.data;
              }
              this.isLoading = false;
              this.valueChange.emit(this.value);
            },
            (error) => {
              console.error("Error uploading file: ", error);
              this.isLoading = false;
            }
          );
        } else {
          this.value = "";
          this.valueChange.emit(this.value);
        }
      }
    }
  }
}
