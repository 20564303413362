import { Component,OnInit,OnDestroy } from '@angular/core';
import {Subscription} from "rxjs";
import {AuthService} from "../../auth/auth.service";
import {Router} from '@angular/router';
import {AppService} from "../../app.service";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit,OnDestroy {


  newMessageCount = 0;
  //messageObserver = new Subject();

  member_loggedIn_status_subscription?: Subscription;
  
  feature_enabled_subscriptipn?: Subscription;
  isDataLoaded = false;

  //newMessageCountDup = this.messageService.checkForNewMessagesCount();
  loggedInUser:string = ''

  tokenUsageAllowedSubscription :Subscription | undefined

  isTokenUsageAllowed : boolean = false;

  isPricingFeatureEnabled : boolean = true;
  
  chat_bot_id = environment.chat_bot_id;
  chat_bot_webkey = environment.chat_bot_webkey;
  qa_bot_id = environment.qa_bot_id;
  qa_bot_webkey = environment.qa_bot_webkey;
  
  

  constructor(private router: Router , private authService: AuthService,private appService: AppService) { }

  ngOnInit() {
    console.log(' header component ngOnInit called ' + this.loggedInUser);
    this.member_loggedIn_status_subscription = this.authService.member_loggedIn_status_subject.subscribe(
      (data) => {
        if (data) {
          this.loggedInUser = this.authService.getAuthenticatedMember();
        } else {
          this.loggedInUser = '';
        }
        //console.log('!!!!!!!in header component ' + this.loggedInUser);
      },
      (error) => {
        this.loggedInUser = '';
        console.error(' error subscribe errored out' + error);

      }
   );

    
      this.feature_enabled_subscriptipn = this.appService.feature_enabled_subject.subscribe(
          (data) => {
              this.isPricingFeatureEnabled = data;
          },
          (error) =>{
              this.isPricingFeatureEnabled = true;
          }
      )

    this.loggedInUser = this.authService.getAuthenticatedMember();
    this.appService.getTokenUsageAllowedStatus();

    this.appService.isFeatureEnabled('pricing')

  }



  onSignout() {
    this.authService.signout();
    this.router.navigate(['/signin']);
  }

  isAuthenticated() {
   //console.log('in Headercomponent --> isAuthenticated --> '+this.authService.isAuthenticated());
    return this.authService.isAuthenticated();
  }

  ngOnDestroy() {
    this.member_loggedIn_status_subscription?.unsubscribe();
    this.tokenUsageAllowedSubscription?.unsubscribe();
    this.feature_enabled_subscriptipn?.unsubscribe();

  }



}
