<div
    class="instruction mb-5"
    *ngIf="isDataLoaded && !isTokenUsageAllowed"
    style="text-align: center"
    >
    <b
    >Your Free trial has expired or you have exceeded the allowed free token
    limit. Please Subscribe
    </b>
</div>
<div class="row" *ngIf="!loading">
    <div class="container chat-head" *ngIf="toolList != null && toolList.length > 0">
        <div class="row">
            <div class="col-6 d-flex justify-content-start align-items-center">
                <h1>My Bots</h1>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center">
                <button type="submit" class="btn knownew btn-lg" (click)="addnew()">
                    Create Bot
                </button>
            </div>
        </div>
    </div>

    <div class="row no-bot text-center" *ngIf="toolList == null || toolList.length === 0">
       <div class="text-center d-flex justify-content-end align-items-center mb-4">
            <button type="submit" class="btn knownew btn-lg" (click)="addnew()">
                Create Bot
            </button>
        </div> 
        <app-userguide></app-userguide>
    </div>

    <div class="card-container" *ngIf="toolList != null && toolList.length > 0">
        <div class="card position-relative" *ngFor="let tool of toolList; let i = index">
          <div class="card__header d-flex justify-content-center pt-4">
            <img [src]="$any(tool)?.botSettingDto?.iconUrl" height="50">
          </div>
          <div class="card__body text-center">
            <p class="mb-0 type">{{ tool.id }}</p>
            <h5 class="mb-0">{{ tool.name }}</h5>
            <p class="mb-0 type">{{ tool.toolTypeName }}</p>
            <div class="user__info">
                <small class="type">{{ tool.createdAt | date : "M/d/yyyy h:mm a" }}</small>
              </div>
            <div class="position-absolute status-dot" 
                [matTooltip]="$any(tool)?.status"
                [matTooltipPosition]="'below'">
                <span class="tactive" *ngIf="$any(tool)?.status == 'ACTIVE'"></span>
                <span class="tinactive" *ngIf="$any(tool)?.status != 'ACTIVE'"></span>
            </div>
          </div>
          <div class="card__footer d-flex flex-column text-center align-items-center">
            <div class="hstack gap-3 py-3">
                <a href="javascript:void(0)" matTooltip="Configure Bot" [matTooltipPosition]="'below'" (click)="config(i)">
                    <svg fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 430.848 430.848" xml:space="preserve">
                        <g>
                            <path d="M286.244,249.537l10.992-45.639c0.035-0.143,0.071-0.285,0.109-0.428c2.909-10.867,11.469-19.426,22.339-22.338
                                l33.347-8.936c-5.472-6.525-13.678-10.682-22.839-10.682h-9.837c-2.511-7.895-5.7-15.59-9.515-22.957l6.96-6.959
                                c11.622-11.623,11.622-30.535,0-42.156L296.76,68.4c-5.631-5.629-13.117-8.73-21.079-8.73c-7.961,0-15.447,3.102-21.078,8.732
                                l-6.96,6.959c-7.369-3.814-15.064-7.004-22.956-9.516V56.01c0-16.436-13.372-29.807-29.808-29.807h-29.758
                                c-16.436,0-29.808,13.371-29.808,29.807v9.836c-7.893,2.512-15.588,5.701-22.957,9.516l-6.96-6.961
                                c-5.631-5.629-13.117-8.73-21.078-8.73c-7.961,0-15.447,3.102-21.079,8.732L42.2,89.443c-11.622,11.621-11.622,30.533,0,42.156
                                l6.959,6.959c-3.815,7.367-7.004,15.063-9.515,22.957h-9.837C13.372,161.516,0,174.887,0,191.324v29.758
                                c0,16.436,13.372,29.807,29.808,29.807h9.837c2.511,7.895,5.7,15.588,9.515,22.957l-6.96,6.959
                                c-11.623,11.623-11.623,30.533,0,42.158l21.041,21.039c5.632,5.631,13.118,8.732,21.079,8.732s15.447-3.102,21.077-8.732
                                l6.96-6.959c7.366,3.815,15.061,7.002,22.957,9.514v9.838c0,16.436,13.372,29.809,29.808,29.809h25.809
                                c-2.388-5.691-3.644-11.852-3.645-18.209c-0.002-12.572,4.892-24.391,13.781-33.279L286.244,249.537z M180,286.201
                                c-44.112,0-80-35.887-80-79.998c0-44.113,35.888-80.002,80-80.002s80,35.889,80,80.002C260,250.314,224.112,286.201,180,286.201z"
                            />
                            <path d="M425.267,218.734l-0.319-0.32c-0.939-0.941-2.189-1.428-3.541-1.469c-1.326,0-2.598,0.525-3.536,1.465l-21.596,21.596
                                c-3.304,3.305-7.699,5.125-12.375,5.125c-4.676,0-9.072-1.82-12.379-5.129c-3.307-3.305-5.128-7.701-5.128-12.377
                                c0.001-4.676,1.821-9.072,5.126-12.377l21.596-21.596c0.939-0.939,1.465-2.213,1.464-3.539c-0.001-1.328-0.53-2.6-1.47-3.537
                                l-0.314-0.313c-3.605-3.605-8.399-5.592-13.499-5.592c-1.665,0-3.325,0.219-4.936,0.65l-44.348,11.885
                                c-6.568,1.76-11.741,6.932-13.498,13.496c-0.011,0.041-0.021,0.08-0.031,0.121l-11.817,49.063l-87.667,87.666
                                c-6.528,6.527-10.122,15.207-10.121,24.44c0.002,9.232,3.598,17.91,10.126,24.439l2.088,2.088
                                c6.528,6.529,15.209,10.125,24.443,10.125h0c9.231,0,17.909-3.594,24.437-10.121l87.667-87.666l49.061-11.816
                                c0.041-0.01,0.082-0.022,0.122-0.031c6.563-1.758,11.735-6.928,13.497-13.496l11.883-44.352
                                C431.959,230.598,430.066,223.535,425.267,218.734z M257.26,368.406c-1.888,1.889-4.399,2.93-7.071,2.93
                                c-2.671,0-5.183-1.041-7.072-2.932c-1.887-1.885-2.928-4.397-2.928-7.068c-0.001-2.672,1.041-5.185,2.931-7.072
                                c1.886-1.887,4.398-2.928,7.069-2.928c2.672,0,5.184,1.041,7.072,2.93c1.887,1.885,2.928,4.396,2.928,7.068
                                C260.189,364.006,259.148,366.518,257.26,368.406z M316.194,305.935L274.82,347.31c-1.416,1.416-3.3,2.197-5.303,2.197
                                c-2.003,0-3.887-0.781-5.303-2.197c-1.417-1.416-2.197-3.299-2.197-5.303s0.78-3.887,2.197-5.303l41.374-41.375
                                c1.417-1.418,3.3-2.197,5.303-2.197s3.887,0.779,5.303,2.197c1.417,1.416,2.197,3.299,2.197,5.303S317.611,304.519,316.194,305.935
                                z"/>
                        </g>
                    </svg>
                </a>
                <a href="javascript:void(0)" matTooltip="Chat History" [matTooltipPosition]="'below'" (click)="openChatHistory(tool.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525C3.22094 16.8088 3.28001 17.2161 3.17712 17.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L6.39939 20.8229C6.78393 20.72 7.19121 20.7791 7.54753 20.9565C8.88837 21.6244 10.4003 22 12 22Z" stroke="#000" stroke-width="1.5" style="&#10;"/>
                        <path opacity="0.5" d="M8 10.5H16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                        <path opacity="0.5" d="M8 14H13.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                </a>
            </div>
          </div>
        </div>
      </div>
</div>
<div class="row d-flex justify-content-center" *ngIf="loading">
    <div id="loading"></div>
    <div class="text-center pt-5">Please wait, while we are fetching bots...</div>
</div>