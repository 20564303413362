import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { ToolService } from '../tool.service'
import { Router } from '@angular/router'
import { ToolModel } from '../tool.model'
import { Subscription } from 'rxjs'
import {
  NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap'
import { MatDialog } from '@angular/material/dialog'
import { HttpResponse } from '@angular/common/http'
import { CreateBotsComponent } from '../create-bots/create-bots.component'
import { ChatHistoryViewComponent } from '../chat-history-view/chat-history-view.component'
import { AppService } from 'src/app/app.service'

@Component({
  selector: 'app-tool-list',
  templateUrl: './tool-list.component.html',
  styleUrls: ['./tool-list.component.css'],
})
export class ToolListComponent implements OnInit, OnDestroy {
  //@ViewChild('listContainer') listContainer: ElementRef;

  @ViewChild('chatContainer') chatContainer: ElementRef | undefined
  // ngAfterViewInit() {
  //     console.log(this.hiddentDiv?.nativeElement);
  // }

  isCollapsed = false
  toolList: ToolModel[] = [];
  loading = false;

  selectedToolIndex: number = 0

  selectedValueStatus: string = 'Active'

  toolAddedSubscription: Subscription | undefined
  toolListSubSscription: Subscription | undefined
  initChatAgentSubscription: Subscription | undefined
  chatSubscription: Subscription | undefined
  querySubscription: Subscription | undefined
  tokenUsageAllowedSubscription: Subscription | undefined
  isTokenUsageAllowed : boolean = false;
  isDataLoaded : boolean = false;
  chatstatus: string = ''

  apiStr: string = ''

  closeResult = ''

  modal: NgbActiveModal | undefined

  selectedToolForDetails: ToolModel | undefined

  queryStr: string = ''

  queryResponse: string = ''

  constructor(
    private router: Router,
    private toolsService: ToolService,
    public dialog: MatDialog,
    private appService: AppService
  ) {}

  ngOnInit() {
    console.log('ToolListComponent created ')
    this.loading = true;
    this.toolListSubSscription = this.toolsService.toolListSubject.subscribe(
      (response) => {
        this.loading = false;
        this.toolList = response
      },
      (error) => {
        this.loading = false;
        console.log(error)
      }
    )
    this.tokenUsageAllowedSubscription = this.appService.tokenUsageAllowedStatusSubject.subscribe(
      (data)=>{
        console.log("--- token usage allowed "+data);
        this.isTokenUsageAllowed = data;
        this.isDataLoaded = true;
      },
      (error) =>{
        console.error("Error occured while getting tokenusage status from server");
        this.isTokenUsageAllowed = false;
        this.isDataLoaded = true;
      }
  )

    this.toolAddedSubscription = this.toolsService.toolCreatedSubject.subscribe(
      () => {
        console.log(' -- ToolListComponent --- another tool Added--- ')
        this.toolsService.getMyToolslist()
      },
      () => {
        console.log('ToolListComponent : error occured in tool creation.')
      }
    )

    this.toolsService.getMyToolslist()
  }

  ngOnDestroy() {
    this.toolAddedSubscription?.unsubscribe()
    this.toolListSubSscription?.unsubscribe()
    this.chatSubscription?.unsubscribe()
    this.querySubscription?.unsubscribe()
  }

  // initTool(index: number) {
  //
  //     this.selectedToolIndex = index;
  //
  //     if(this.toolList[index].toolType == '1') {
  //         this.initChatAgent(index);
  //     } else if(this.toolList[index].toolType == '2') {
  //         this.showQueryDiv = true;
  //         this.showchat = false;
  //
  //
  //     }
  //
  //
  //
  // }

  edit(index: number) {
    var tool = this.toolList[index]

    this.router.navigate(['/dashboard/tools/edit'], {
      queryParams: { toolId: tool.id, toolName: tool.name },
    })
  }

  config(index: number) {
    var tool = this.toolList[index]
    this.router.navigate(['/dashboard/tools/config'], {
      queryParams: { toolId: tool.id, toolName: tool.name },
    })
  }

  tryTool(index: number) {
    var tool = this.toolList[index]

    this.router.navigate(['/dashboard/tools/try'], {
      queryParams: {
        toolId: tool.id,
        toolName: tool.name,
        toolType: tool.toolType,
      },
    })
  }

  downloadIntegrationGuide(index: number) {
    var tool = this.toolList[index]
    this.toolsService
      .downloadIntegrationGuide(tool.id)
      .subscribe((response) => this.handleFileDownload(response))
  }

  private handleFileDownload(response: HttpResponse<Blob>) {
    const contentDispositionHeader = response.headers.get('Content-Disposition')
    const headers = response.headers.keys()
    headers.forEach((header) => {
      const headerValue = response.headers.get(header)
      console.log(`${header}: ${headerValue}`)
    })

    if (contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = fileNameRegex.exec(contentDispositionHeader)

      const fileName =
        matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'download'

      if (response.body) {
        // Create a blob from the response and trigger the download
        const blob = new Blob([response.body], { type: response.body.type })
        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(blob)
        downloadLink.setAttribute('download', fileName)
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      } else {
        console.error(' response body not found.')
      }
    } else {
      console.error(
        'Content-Disposition header not found. Unable to determine filename.'
      )
    }
  }

  changeStatus(index: number) {
    if (index >= 0 && index < this.toolList.length) {
      //   this.toolList[index].status = status; // Assuming there's a 'status' property in your toolList item
    }
  }

  addnew() {
    this.dialog.open(CreateBotsComponent , {
      panelClass: 'addnew'
    });
  }


  openChatHistory(id: number) {
    this.dialog.open(ChatHistoryViewComponent, {
      width: '550px',
      data: { id }
    });
  }

  // this.dialogService.confirm({ title: "Cancel Lease", message: "Are you sure you want to request cancel this lease ?" }).result.then((t: any) => {

  //     console.log(t)
  //   });

  //EXAMPLE
  // public transformedUser: TransformedUser | undefined;
  //
  // constructor(private http: HttpClient) {}
  //
  // ngOnInit() {
  //     this.http.get<User>('https://jsonplaceholder.typicode.com/users/1')
  //         .pipe(
  //             map((user: User) => {
  //                 const transformedUser: TransformedUser = {
  //                     fullName: user.name,
  //                     emailAddress: user.email,
  //                 };
  //                 return transformedUser;
  //             })
  //         )
  //         .subscribe((transformedUser: TransformedUser) => {
  //             this.transformedUser = transformedUser;
  //         });
  // }
}
