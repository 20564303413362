<div class="container p-0">
  <div class="third">
    <h4 class="title pb-2">Current Configuration of {{ toolName }}:</h4>
    <div class="mi-config">
      <section>
        <p style="font-style: italic">
          These are advanced settings. Changing these can affect the output of
          this tool significantly.
        </p>
      </section>
      <form
        class="d-flex row col-12"
        name="toolsForm"
        #myForm="ngForm"
        (ngSubmit)="updateTool()"
      >
        <div class="form-group col-12 mt-3">
          <input
            type="checkbox"
            name="applyMetaDataFilter"
            id="applyMetaDataFilter"
            class="form-check-inline"
            [(ngModel)]="toolModel.toolConfigDto.applyMetaDataFilter"
          /><label for="applyMetaDataFilter">Apply Keywords Filter while querying</label>
        </div>

        <div class="form-group col-12 mt-3">
          <input
            type="checkbox"
            name="applyRecencyFilter"
            id="applyRecencyFilter"
            class="form-check-inline"
            [(ngModel)]="toolModel.toolConfigDto.applyRecencyFilter"
          /><label for="applyRecencyFilter">Use the latest version of the document.</label>
        </div>

        <div class="form-group col-12 mt-3">
          <label for="temperature"
            >Temperature(Between 0 and 1):
            {{ toolModel.toolConfigDto.temperature }}</label
          >
          <input
            type="range"
            class="form-range"
            name="temperature"
            id="temperature"
            min="0"
            max="1"
            step="0.1"
            [(ngModel)]="toolModel.toolConfigDto.temperature"
          />
        </div>
        <div class="form-group col-12 mt-3">
          <label for="maxChunkOverlap"
            >Max Chunk Overlap(Between 0 and 1) :{{
              toolModel.toolConfigDto.maxChunkOverlap
            }}</label
          >
          <input
            type="range"
            class="form-range"
            name="maxChunkOverlap"
            id="maxChunkOverlap"
            min="0"
            max="1"
            step="0.1"
            [(ngModel)]="toolModel.toolConfigDto.maxChunkOverlap"
          />
        </div>
        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="maxOutputSize">Max output size:</mat-label>
            <input
              matInput
              type="text"
              id="maxOutputSize"
              name="maxOutputSize"
              ngModel
              required
              [(ngModel)]="toolModel.toolConfigDto.maxOutputSize"
              #maxOutputSize="ngModel"
            />
            <mat-error *ngIf="!maxOutputSize.valid && maxOutputSize.touched"
              >Max output size is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="maxInputSize">Max Input size:</mat-label>
            <input
              matInput
              type="text"
              id="maxInputSize"
              name="maxInputSize"
              ngModel
              required
              [(ngModel)]="toolModel.toolConfigDto.maxInputSize"
              #maxInputSize="ngModel"
            />
            <mat-error *ngIf="!maxInputSize.valid && maxInputSize.touched"
              >Max input size is required.</mat-error
            >
          </mat-form-field>
        </div>

        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="maxTokens">Max Tokens:</mat-label>
            <input
              matInput
              type="text"
              id="maxTokens"
              name="maxTokens"
              ngModel
              required
              [(ngModel)]="toolModel.toolConfigDto.maxTokens"
              #maxTokens="ngModel"
            />
            <mat-error *ngIf="!maxTokens.valid && maxTokens.touched"
              >Max Tokens is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="topKSimilarity">Top K Similarity:</mat-label>
            <input
              matInput
              type="text"
              id="topKSimilarity"
              name="topKSimilarity"
              ngModel
              required
              [(ngModel)]="toolModel.toolConfigDto.similarityTopK"
              #similarityTopK="ngModel"
            />
            <mat-error *ngIf="!similarityTopK.valid && similarityTopK.touched"
              >Max Tokens is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="llmProvider">LLM Provider:</mat-label>
            <input
              matInput
              type="text"
              id="llmProvider"
              ngModel
              [(ngModel)]="toolModel.toolConfigDto.llmProvider"
              #llmProvider="ngModel"
              name="llmProvider"
              [readonly]="true"
            />
          </mat-form-field>
        </div>
        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="llmModel">LLM Model:</mat-label>
            <input
              matInput
              type="text"
              id="llmModel"
              ngModel
              [(ngModel)]="toolModel.toolConfigDto.modelName"
              #modelName="ngModel"
              name="llmModel"
              [readonly]="true"
            />
          </mat-form-field>
        </div>
        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="embeddingProvider">Embedding Provider:</mat-label>
            <input
              matInput
              type="text"
              id="embeddingProvider"
              ngModel
              [(ngModel)]="toolModel.toolConfigDto.embeddingProvider"
              #embeddingProvider="ngModel"
              name="embeddingProvider"
              [readonly]="true"
            />
          </mat-form-field>
        </div>
        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="embeddingModel">Embedding Model:</mat-label>
            <input
              matInput
              type="text"
              id="embeddingModel"
              ngModel
              [(ngModel)]="toolModel.toolConfigDto.embeddingModel"
              #embeddingModel="ngModel"
              name="embeddingModel"
              [readonly]="true"
            />
          </mat-form-field>
        </div>

        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="contextPromptId">Context Prompt</mat-label>
            <mat-select
              id="contextPromptId"
              name="selectedContextPromot"
              [(ngModel)]="toolModel.toolConfigDto.contextPromptDto"
              [compareWith]="comparePrompts"
            >
              <mat-option
                *ngFor="let contextPrompt of contextPromptList"
                [value]="contextPrompt"
                >{{ contextPrompt.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="systemPromptId">System Prompt</mat-label>
            <mat-select
              id="systemPromptId"
              name="selectedSystemPromot"
              [(ngModel)]="toolModel.toolConfigDto.systemPromptDto"
              [compareWith]="comparePrompts"
            >
              <mat-option
                *ngFor="let prompt of systemPromptList"
                [value]="prompt"
                >{{ prompt.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="condensePromptId">Condense Prompt</mat-label>
            <mat-select
              id="condensePromptId"
              name="selectedCondensePrompt"
              [(ngModel)]="toolModel.toolConfigDto.condensePromptDto"
              [compareWith]="comparePrompts"
            >
              <mat-option
                *ngFor="let prompt of condensePromptList"
                [value]="prompt"
                >{{ prompt.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6 mt-3">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label for="condenseQuestionPromptId"
              >Condense Question Prompt</mat-label
            >
            <mat-select
              id="condenseQuestionPromptId"
              name="selectedCondenseQuestionPrompt"
              [(ngModel)]="toolModel.toolConfigDto.condenseQuestionPromptDto"
              [compareWith]="comparePrompts"
            >
              <mat-option
                *ngFor="let prompt of condenseQuestionPromptList"
                [value]="prompt"
                >{{ prompt.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div
          class="button-container text-left d-flex flex-row-reverse"
          style="margin-top: 1em"
        >
          <button type="submit" class="btn upd" [disabled]="!myForm.valid">
            Update
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
          <button type="button" class="btn can" style="margin-left: 1em">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
