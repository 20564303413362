<div class="container">
  <p class="title">Deployment</p>
  <!-- <p class="subtitle">
    Your bot is
    <ng-container *ngIf="deployedItemsCount > 0; else notDeployed">
      deployed on
      <span class="bold">{{ deployedItemsCount }} channel(s)</span>
    </ng-container>
    <ng-template #notDeployed> not deployed </ng-template>
  </p> -->
  <div class="deployment-tab">
    <p class="sutitle">Your bot is ready for deployment</p>
    <div style="flex-grow: 1; overflow: hidden">
      <div class="flex-container">
        <div class="channel-container">
          <ng-container *ngFor="let item of deployments">
            <d-channel *ngIf="item.added" [item]="item" class="channel" />
          </ng-container>
          <p class="more-channels-title">More Integrations</p>
          <ng-container *ngFor="let item of deployments">
            <d-channel *ngIf="!item.added" [item]="item" class="channel" />
          </ng-container>
        </div>
        <!-- <div class="help-support-container"> -->
          <!-- <p class="help-support-title">Help & Support</p>
          <p class="help-support-text">
            Connect and deploy the bot across multiple channels easily. The end
            user experience may vary across channels. Channels are available for
            deployment as per the subscription plans
          </p>
          <a class="help-support-link">How do I deploy my bot?</a>
          <button class="view-all-resources-button">View All Resources</button> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
