import { Component, NgModule, OnDestroy, OnInit } from '@angular/core'
import { FormsModule, NgForm } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatOptionModule } from '@angular/material/core'
import { ActivatedRoute, Router } from '@angular/router'
import { KbService } from 'src/app/kb/KbService'
import { ToolService } from 'src/app/tools/tool.service'
import { PromptService } from 'src/app/prompt/promptService'
import { MessageService } from 'src/app/shared/services/message.service'
import { Subscription } from 'rxjs'
import { ToolModel } from 'src/app/tools/tool.model'
import { PromptMapWrapper } from 'src/app/prompt/prompt.model'
import { ToolDocModel } from 'src/app/tools/tool-doc.model'
import { MatSelectModule } from '@angular/material/select'

/** @title Form field appearance variants */
@Component({
  selector: 'upload-documents-tab',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
  ],
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css'],
})
export class UploadDocumentsComponent implements OnInit, OnDestroy{
  toolId: any
  toolName: any
  toolModel = new ToolModel()
  isLoading: boolean = false
  isError: boolean = false
  isSuccess: boolean = false
  isLoadingUpdateKeywords: number | boolean = false
  addDocumentMessage: string = ''
  toolDocModelList: ToolDocModel[] = []
  toolGetSubscription: Subscription | undefined
  toolUpdateSubscription: Subscription | undefined
  addDocSubscription: Subscription | undefined
  getToolDocListSubscription: Subscription | undefined
  updateKeywordsSubecription: Subscription | undefined
  promptMapSubscription: Subscription | undefined
  fileUploadDataSourceSelected = false
  googleDocsDataSourceSelected = false
  webPageDataSourceSelected = false
  webSiteDataSourceSelected = false
  file: File | undefined
  srcType: string = ''
  srcDataGoogleDocs: string = ''
  srcDataWebPages: string = ''
  promptMapWrapper: PromptMapWrapper | undefined
  contextPromptList: any = []
  condenseQuestionPromptList: any = []
  condensePromptList: any = []
  systemPromptList: any = []
  constructor(
    private route: ActivatedRoute,
    private toolsService: ToolService,
    private promptService: PromptService,
    public messageService: MessageService
  ) {}



  ngOnInit() {
    console.log('ToolListComponent created ')
    this.toolId = this.route.snapshot.queryParamMap.get('toolId')
    this.toolName = this.route.snapshot.queryParamMap.get('toolName')
    console.log('this.toolId ' + this.toolId)

    this.toolGetSubscription = this.toolsService.getToolSubject.subscribe(
      (response: ToolModel) => {
        this.toolModel = response
        console.log(' returned toolModel = ' + JSON.stringify(this.toolModel))
        this.isLoading = false
        //this.updateSelectedPrompts();
      },
      (error: any) => {
        console.log(error)
        this.isLoading = false
      }
    )
    this.getToolDocListSubscription =
      this.toolsService.getToolDocListSubject.subscribe(
        (response: ToolDocModel[]) => {
          console.log('ToolDocModel list ' + JSON.stringify(response))
          this.toolDocModelList = response;
        },
        (error: any) => {
          console.log(error)
        }
      )

    this.toolUpdateSubscription = this.toolsService.updateToolSubject.subscribe(
      (response: any) => {
        this.isSuccess = true
        this.toolsService.getTool(this.toolId)
        this.messageService.showSuccess(response.message)
        this.isLoading = false
        // this.showServerMessage('updateToolMessage',response.message)
      },
      (error: any) => {
        this.isError = true
        this.messageService.showError(error)
        this.isLoading = false
        // this.showServerMessage('updateToolMessage',error)
      }
    )

    this.addDocSubscription = this.toolsService.addDocSubject.subscribe(
      (response: any) => {
        this.isSuccess = true
        this.toolsService.getTool(this.toolId)
        this.messageService.showSuccess(response.message)
        this.isLoading = false
        // this.showServerMessage('addDocMessage', response.message)
      },
      (error: any) => {
        this.isError = true
        this.messageService.showError(error)
        this.isLoading = false
        // this.showServerMessage('addDocMessage', error)
      }
    )

    this.updateKeywordsSubecription =
      this.toolsService.updateKeywordsSubject.subscribe(
        () => {
          this.isLoadingUpdateKeywords = false
        },
        () => {
          this.isLoadingUpdateKeywords = false
        }
      )

    this.promptMapSubscription =
      this.promptService.getPromptMapSubject.subscribe(
        (response: PromptMapWrapper) => {
          this.promptMapWrapper = response
          console.log('Prompt Map ' + JSON.stringify(this.promptMapWrapper))
          console.log(typeof this.promptMapWrapper)
          console.log(typeof this.promptMapWrapper?.promptMap)
          console.log(
            'this.promptMapWrapper.promptMap ' +
              JSON.stringify(this.promptMapWrapper?.promptMap)
          )
          if (this.promptMapWrapper) {
            this.contextPromptList =
              this.promptMapWrapper.promptMap.get('ContextPrompt')
            this.condensePromptList =
              this.promptMapWrapper.promptMap.get('CondensePrompt')
            this.condenseQuestionPromptList =
              this.promptMapWrapper.promptMap.get('CondenseQuestionPrompt')
            this.systemPromptList =
              this.promptMapWrapper.promptMap.get('SystemPrompt')
            console.log(
              'this.systemPromptList ' + JSON.stringify(this.systemPromptList)
            )
          }
        },
        (error) => {
          console.log('Error occured while gettting prompt map ' + error)
        }
      )

    this.promptService.getPromptMap()
    this.toolsService.getTool(this.toolId)
    this.toolsService.getDocListForTool(this.toolId)
  }
  addDocument(form: NgForm) {
    const formData = new FormData()
    console.log(this.srcType)
    if (this.fileUploadDataSourceSelected && this.file) {
      formData.append('uploadedFile', this.file)
      console.log('Goig to upload')
      this.toolsService.addDocToTheTool(formData, this.toolId)
    } else if (this.webPageDataSourceSelected) {
      formData.append('webPageList', this.srcDataWebPages)
      console.log('Goig to do web page')
      this.toolsService.addWebPagesToTheTool(formData, this.toolId)
    }
    this.isLoading = true
  }
  onDataSourceOptionChange(event: any) {
    const value = event.value
    this.turnoffAllShowDataSourceFlags()
    if (value === 'File') {
      this.fileUploadDataSourceSelected = true
    } else if (value === 'GoogleDocs') {
      this.googleDocsDataSourceSelected = true
    } else if (value === 'WebPages') {
      this.webPageDataSourceSelected = true
    } else if (value === 'Website') {
      this.webSiteDataSourceSelected = true
    }
  }
  turnoffAllShowDataSourceFlags() {
    this.fileUploadDataSourceSelected = false
    this.googleDocsDataSourceSelected = false
    this.webPageDataSourceSelected = false
    this.webSiteDataSourceSelected = false
  }
  updateKeywords(idx: number) {
    this.isLoadingUpdateKeywords = idx;
    var toolDocModel = this.toolDocModelList[idx]
    this.toolsService.updateKeyWords(
      this.toolId,
      toolDocModel.id,
      toolDocModel.keywords
    )
  }

  trimName() {
    this.srcDataWebPages = this.srcDataWebPages.trim();
  }

  onFileChange(files: FileList): void {
    if (files.length > 0) {
      const file = files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
  
      const validExtensions = ['txt', 'pdf', 'doc'];
  
      if (!validExtensions.includes(fileExtension || '')) {
        this.messageService.showError('Invalid file type. Please upload a .txt, .pdf, or .doc file.');
        (document.getElementById('localFile') as HTMLInputElement).value = '';
      } else {
        this.file = file;
      }
    }
  }  

  ngOnDestroy(): void {
    this.toolGetSubscription?.unsubscribe();
    this.toolUpdateSubscription?.unsubscribe();
    this.getToolDocListSubscription?.unsubscribe();
    this.addDocSubscription?.unsubscribe();
    this.promptMapSubscription?.unsubscribe();
    this.updateKeywordsSubecription?.unsubscribe();
  }
}
