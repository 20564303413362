

<mat-dialog-content>
    <div class="modal-body" style="min-width: 400px;">
        <div class="row bot-header">
            <div class="col-8 d-flex justify-content-center flex-column align-items-center p-0">
                <h5 class="mb-0">Create New Tool</h5>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <!-- <mat-dialog-actions>
                    <button mat-raised-button color="primary" mat-dialog-close tabindex="-1">cancel</button>
                </mat-dialog-actions> -->
                <button mat-icon-button mat-dialog-close tabindex="-1">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#fff" height="20px" width="20px" version="1.1" id="Capa_1" viewBox="0 0 490 490" xml:space="preserve">
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490   489.292,457.678 277.331,245.004 489.292,32.337 "/>
                    </svg>
                </button>
            </div>
        </div>
       
        <div class="d-flex flex-column" *ngIf="!showQuickCreate">
            <div class="container-img botss d-flex align-items-center"  (click)="showQuickCreate=true ; selectedToolType = '1'">
                <a alt="Build Chatbot">
                    <img src="assets/ChatBot.png" width="70" height="70" alt="Build Chatbot" />
                </a>
                <div style="margin-left:1em">
                    <h5>Build Chatbot</h5>
                    <p>Upload document to create a bot</p>
                </div>


            </div>

            <div class="container-img botss d-flex align-items-center" (click)="showQuickCreate=true ; selectedToolType = '2'">
                <a>
                    <img src="assets/QABot.png" width="70" height="70" alt="Build Question Answer bot" />
                </a>
                <div style="margin-left:1em">
                    <h5>Question Bot</h5>
                    <p>Build Question - answer bot</p>
                </div>

            </div>

        </div>

        <div id="quickCreate" [hidden]="!showQuickCreate">

            <form (ngSubmit)="createBot(f)" #f="ngForm">

                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label for="botName">Name:</mat-label>
                            <input matInput type="text" id="botName" name="botName" ngModel required
                                   [(ngModel)]="botNameText" #botName="ngModel" [minLength]="5"
                                   [maxLength]="200" (focusout)="trimBotName()" />

                            <mat-error *ngIf="!botName.valid && botName.touched">Name is required</mat-error>
                        </mat-form-field>
                    </div>
                </div>

<!--                <div class="row">-->
<!--                    <div class="col-md-12">-->
<!--                        <mat-form-field appearance="outline" style="width: 100%;">-->
<!--                            <mat-label for="dataSourceDropDown">Document Type</mat-label>-->
<!--                            <mat-select name="srcType" id="dataSourceDropDown" [(ngModel)]="srcType"-->
<!--                                (selectionChange)="onDataSourceOptionChange($event)">-->
<!--                                <mat-option value="File">Upload Document (.txt/.pdf/.doc)</mat-option>-->
<!--                                <mat-option value="WebPages">Web Page</mat-option>-->
<!--                                <mat-option value="GoogleDocs" disabled>Google Docs (Coming Soon)</mat-option>-->
<!--                                <mat-option value="S3" disabled>S3 (Coming soon)</mat-option>-->
<!--                            </mat-select>-->
<!--                        </mat-form-field>-->


<!--                        &lt;!&ndash; <label for="dataSourceDropDown">Document Type</label>-->
<!--                        <select class="form-select" name="srcType" id="dataSourceDropDown"  (change)="onDataSourceOptionChange($event)" style="width: 40%;" [(ngModel)]="srcType">-->
<!--                            <option selected>Select an option...</option>-->
<!--                            <option value="File">Upload Document(.txt/.pdf)</option>-->
<!--                            <option value="WebPages">Web Page</option>-->
<!--                            <option value="GoogleDocs" disabled >Google Docs(Coming Soon)</option>-->
<!--                            <option value="S3" disabled>S3 (Coming soon)</option>-->
<!--                        </select> &ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row" style="margin-bottom: 3em">-->
<!--                    <div class="col-md-12">-->
<!--                        <mat-form-field appearance="outline" style="width: 100%">-->
<!--                            <mat-label for="allowedOrigins">Allowed Origins:</mat-label>-->
<!--                            <input matInput type="text" id="allowedOrigins" name="allowedOrigins" ngModel required-->
<!--                                   [(ngModel)]="allowedOriginsText" #allowedOrigins="ngModel" [minLength]="5"-->
<!--                                   [maxLength]="200" />-->
<!--                            <mat-hint>The url of the page where bot will be appear.-->
<!--                                It should be exact(including trailing spaces, For example: https://www.mysite.com/home,https://www.mysite.com/home/ )</mat-hint>-->
<!--                            <mat-error *ngIf="!allowedOrigins.valid && allowedOrigins.touched">Allowed list of origins-->
<!--                                should be a valid list of comma seperated domains.</mat-error>-->
<!--                        </mat-form-field>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row mt-3" *ngIf="fileUploadDataSourceSelected">-->

<!--                    <div class="col-md-12 mt-3">-->
<!--                        <label for="localFile">Upload Docs</label>:-->

<!--                        <input type="file" class="form-control" id="localFile" name="localFile" #fileInput-->
<!--                            (change)="file = fileInput.files[0]">-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row mt-3" *ngIf="googleDocsDataSourceSelected">-->
<!--                    <div class="col-md-12">-->
<!--                        <mat-form-field appearance="outline" style="width: 100%">-->
<!--                            <mat-label for="textfield">List of google docs</mat-label>-->
<!--                            <input matInput type="text" id="textarea" name="srcDataGoogleDocs" ngModel required rows="3"-->
<!--                                [(ngModel)]="srcDataGoogleDocs" #textarea="ngModel" />-->
<!--                            <mat-error *ngIf="!textarea.valid && textarea.touched">Web pages is-->
<!--                                required.</mat-error>-->
<!--                        </mat-form-field>-->

<!--                        &lt;!&ndash; <label for="textfield">List of google docs</label>-->
<!--                        <textarea id="textarea" name="srcDataGoogleDocs"  class="form-control" rows="3"  [(ngModel)]="srcDataGoogleDocs"></textarea> &ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row mt-3" *ngIf="webPageDataSourceSelected">-->
<!--                    <div class="col-md-12">-->
<!--                        <mat-form-field appearance="outline" style="width: 100%">-->
<!--                            <mat-label for="textfield">List of web pages</mat-label>-->
<!--                            <input matInput type="text" id="textfield" name="srcDataWebPages" ngModel required rows="3"-->
<!--                                [(ngModel)]="srcDataWebPages" #textfield="ngModel" />-->
<!--                            <mat-error *ngIf="!textfield.valid && textfield.touched">Web pages is-->
<!--                                required.</mat-error>-->
<!--                        </mat-form-field>-->

<!--                        &lt;!&ndash; <label for="textfield">List of web pages</label>-->
<!--                        <input type="text" class="form-control" id="textfield" name="srcDataWebPages" placeholder="single webpage ex: https://en.wikipedia.org/wiki/Metabolite etc." [(ngModel)]="srcDataWebPages"> &ndash;&gt;-->
<!--                    </div>-->

<!--                </div>-->
<!--                <div class="row mt-3" *ngIf="webSiteDataSourceSelected">-->
<!--                    <div class="col-md-12">-->
<!--                        <mat-form-field appearance="outline" style="width: 100%">-->
<!--                            <mat-label for="websiteName">Website Name</mat-label>-->
<!--                            <input matInput type="text" id="websiteName" name="srcDataWebsite" ngModel required rows="3"-->
<!--                                #textfield="ngModel" />-->

<!--                        </mat-form-field>-->

<!--                        &lt;!&ndash; <label for="websiteName">Website Name</label>-->
<!--                        <input type="text" class="form-control" id="websiteName" placeholder="example : www.anywebsite.com" formControlName="srcDataWebsite">-->
<!--                        <div style="font-size: x-small"> We will crawl over the entire website and try to add webpages into KB. Currently only 20 pages are supported</div> &ndash;&gt;-->
<!--                    </div>-->

<!--                </div>-->

                <div class="row mt-3">
                    <div class="col-12 buttton" style="margin-top: 1em;">

                        <div class=" d-flex flex-row-reverse" >
                            <button type="submit" class="btn upd" [disabled]="!botName.valid || botNameText.trim() === ''">Create {{ selectedToolType == '1' ? 'Chatbot'
                                :'QuestionAnswer Bot'}}
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                            </button>
                            <button type="button" class="btn can" (click)="showQuickCreate=false"
                                style="margin-left: 1em">Back</button>
                        </div>
                        <div style="text-align: right">
                            <span style="font-size: x-small;font-weight: bold;font-style: italic">Bot/Tool creation can take a while</span>
                        </div>



                        <!-- <div *ngIf="!webSiteDataSourceSelected" >
                            <button type="submit" class="btn btn-primary btn-sm" >Create {{ selectedToolType == '1' ? 'Chatbot' :'QuestionAnswer Bot'}}
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <button type="button" class="btn btn-secondary btn-sm" style="margin-left: 1em" (click)="showQuickCreate=false">Cancel</button>
                        </div> -->


<!--                        <div class=" d-flex flex-row-reverse" *ngIf="webSiteDataSourceSelected">-->
<!--                            <button type="submit" class="btn upd">Schedule {{ selectedToolType == '1' ? 'Chatbot'-->
<!--                                :'QuestionAnswer Bot'}} Creation-->
<!--                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"-->
<!--                                    aria-hidden="true"></span>-->
<!--                            </button>-->
<!--                            <button type="button" class="btn can" (click)="showQuickCreate=false"-->
<!--                                style="margin-left: 1em">Cancel</button>-->
<!--                        </div>-->

                        <!-- <div *ngIf="webSiteDataSourceSelected" >
                            <button type="submit" class="btn btn-primary btn-sm" >Schedule {{ selectedToolType == '1' ? 'Chatbot' :'QuestionAnswer Bot'}} Creation
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <button type="button" class="btn btn-secondary btn-sm" style="margin-left: 1em" (click)="showQuickCreate=false">Cancel</button>
                        </div> -->




                    </div>



                </div>

                <!-- <div class="row" style="margin-top:0.5em" >
        
                    <div *ngIf="isError" class="alert alert-danger">{{ serverMessage }}</div>
                    <div *ngIf="isSuccess" class="alert-success">{{ serverMessage }}</div>
                </div> -->


            </form>

        </div>
    </div>
</mat-dialog-content>
