import { Component, OnDestroy, ViewChild } from '@angular/core'
import { BotIconComponent } from '../webDisplay/bot-icon/bot-icon.component'
import { LaunchScreenComponent } from '../webDisplay/launch-screen/launch-screen.component'
import { ChatStyleComponent } from '../webDisplay/chat-style/chat-style.component'
import { SettingsComponent } from '../webDisplay/settings/settings.component'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { KbService } from 'src/app/kb/KbService'
import { PromptService } from 'src/app/prompt/promptService'
import { MessageService } from 'src/app/shared/services/message.service'
import { DisplaySettingsModel } from './display-settings.model'
import { Subscription } from 'rxjs'
import { ToolService } from 'src/app/tools/tool.service'

@Component({
  selector: 'display-tab',
  standalone: true,
  imports: [
    CommonModule,
    BotIconComponent,
    LaunchScreenComponent,
    ChatStyleComponent,
    SettingsComponent,
    FormsModule,
    FormsModule,
  ],
  templateUrl: './display-tab.component.html',
  styleUrls: ['./display-tab.component.css'],
})
export class DisplayTabComponent implements OnDestroy {
  activeTab = 0
  @ViewChild('botIconForm') botIconForm: any
  @ViewChild('launchScreenForm') launchScreenForm: any
  @ViewChild('chatStyleForm') chatStyleForm: any
  @ViewChild('settingsForm') settingsForm: any
  toolId: any
  toolName: any
  toolModel!: DisplaySettingsModel;
  isLoading: boolean = false
  tooltips: { fieldHelpKey: string; fieldHelpValue: string }[] = []
  toolGetSubscription: Subscription | undefined
  toolUpdateSubscription: Subscription | undefined
  promptMapSubscription: Subscription | undefined
  isError: boolean = false
  isSuccess: boolean = false
  constructor(
    private route: ActivatedRoute,
    private toolService: ToolService,
    public messageService: MessageService
  ) {}

  ngOnInit() {
    console.log('ToolListComponent created ')
    this.toolId = this.route.snapshot.queryParamMap.get('toolId')
    this.toolName = this.route.snapshot.queryParamMap.get('toolName')
    console.log('this.toolId ' + this.toolId)
    this.fetchTooltip()

    this.toolGetSubscription =
      this.toolService.getToolDisplaySettingsSubject.subscribe(
        (response: DisplaySettingsModel) => {
          this.toolModel = response
          console.log(' returned toolModel = ' ,this.toolModel)
          this.isLoading = false
          //this.updateSelectedPrompts();
        },
        (error: any) => {
          console.log(error)
          this.isLoading = false
        }
      )

    this.toolUpdateSubscription =
      this.toolService.updateToolDisplaySettingsSubject.subscribe(
        (response: any) => {
          this.isSuccess = true
          this.toolService.getToolDisplaySettings(this.toolId)
          this.messageService.showSuccess(response.message)
          this.isLoading = false
          // this.showServerMessage('updateToolMessage',response.message)
        },
        (error: any) => {
          this.isError = true
          this.messageService.showError(error)
          this.isLoading = false
          // this.showServerMessage('updateToolMessage',error)
        }
      )
    this.toolService.getToolDisplaySettings(this.toolId)
    console.log(this.toolModel)
  }
  changeTab(number: number) {
    this.activeTab = number
  }
  submitForm() {
    if (!this.toolModel.iconUrl) {
      this.messageService.showError("Launch Icon is required.")
      return;
    }
    this.isLoading = true
    this.toolService.updateToolDisplaySettings(this.toolModel,this.toolId)
  }
  fetchTooltip() {
    this.toolService.getTooltip().subscribe((data) => {
      this.tooltips = data
      console.log(data)
    })
  }
  getBotNameTooltip(str: string): string | undefined {
    return this.tooltips.find((t) => t.fieldHelpKey === str)?.fieldHelpValue
  }
  ngOnDestroy(): void {
    this.toolGetSubscription?.unsubscribe();
    this.toolUpdateSubscription?.unsubscribe();
  }
}
