<div class="item-container">
  <div class="item-info-container">
    <!-- <img [src]="item.logoUrl" alt="Item Logo" class="item-image" /> -->
    <div>
      <p class="item-name">{{ item.name }}</p>
      <p class="item-description">{{ item.description }}</p>
      <div style="flex-basis: 100%;">

      </div>
      <div>
        <button type="button" name="toggle"  (click)="item.show = !item.show" class="btn btn-secondary" style="padding-left:5px;padding-right: 5px;font-size: x-small">
          {{!item.show  ? 'View Script' : 'Hide Script'}}
        </button>
        <div [hidden]="!item.show" style="overflow-x: scroll; max-width: 40vw;">

            <code>
            {{item.code}}
          </code><a class="btn" (click)="copyToClipboard()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16" >
          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
        </svg></a>


        </div>
      </div>
    </div>



</div>

</div>

