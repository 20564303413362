<!-- <ul class="horizontal-menu">
  <li><a href="#" routerLink="/dashboard/tools">Bots</a></li>
  <li><a href="#" routerLink="/dashboard/knowledgeBase">KnowledgeBases</a></li>
  <li><a href="#" routerLink="/dashboard/query">Query</a></li>
  <li><a href="#" routerLink="/dashboard/prompts">Prompts</a></li>
  <li><a href="#" routerLink="/dashboard/settings">Settings</a></li>
  <li><a href="#" routerLink="/dashboard/tokenUsage">TokenUsage</a></li>
</ul>
<div *ngIf="globalMessages != null &&  globalMessages.length > 0 ">

  <div *ngFor="let globalMessage of globalMessages;let i = index" style="text-align: center;background-color:lightgoldenrodyellow;">

    {{globalMessage}}

  </div>

</div>

<div *ngIf="userMessages != null &&  userMessages.length > 0 ">

  <div *ngFor="let userMessage of userMessages;let i = index" style="text-align: center;background-color:lightblue;">

    {{userMessage}}

  </div>

</div> -->

<!-- try -->
<!-- <div class="wrapper">
  
  <nav id="sidebar">
      <div class="sidebarHeader">
          <h3>Sidebar head</h3>
      </div>
      <ul class="list-unstyled components">
          <li>
              <a href="#">About</a>
          </li>
          <li>
              <a href="#">About</a>
          </li>
          <li>
              <a href="#">About</a>
          </li>
          <li>
              <a href="#">About</a>
          </li>
          <li>
              <a href="#">About</a>
          </li>
          <li>
              <a href="#">About</a>
          </li>
          
      </ul>
  </nav>
</div> -->

<div
  class="page-wrapper"
  id="main-wrapper"
  data-layout="vertical"
  data-navbarbg="skin6"
  data-sidebartype="full"
  data-sidebar-position="fixed"
  data-header-position="fixed"
>
  <aside class="left-sidebar">
    <div>
      <div
        class="brand-logo d-flex align-items-center justify-content-between"
      ></div>
      <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
        <ul id="sidebarnav">
          <li class="sidebar-item">
            <a
              class="sidebar-link"
              routerLink="/dashboard/tools"
              routerLinkActive="active"
              aria-expanded="false"
              aria-expanded="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-home"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#fff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
              </svg>
            </a>
          </li>

<!--          <li class="sidebar-item">-->
<!--            <a-->
<!--              class="sidebar-link"-->
<!--              routerLink="/dashboard/knowledgeBase"-->
<!--              routerLinkActive="active"-->
<!--              aria-expanded="false"-->
<!--              aria-expanded="false"-->
<!--            >-->
<!--              <svg-->
<!--                xmlns="http://www.w3.org/2000/svg"-->
<!--                class="icon icon-tabler icon-tabler-notes"-->
<!--                width="24"-->
<!--                height="24"-->
<!--                viewBox="0 0 24 24"-->
<!--                stroke-width="2"-->
<!--                stroke="#fff"-->
<!--                fill="none"-->
<!--                stroke-linecap="round"-->
<!--                stroke-linejoin="round"-->
<!--              >-->
<!--                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>-->
<!--                <path-->
<!--                  d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"-->
<!--                ></path>-->
<!--                <path d="M9 7l6 0"></path>-->
<!--                <path d="M9 11l6 0"></path>-->
<!--                <path d="M9 15l4 0"></path>-->
<!--              </svg>-->
<!--            </a>-->
<!--          </li>-->

          <!--          <li class="sidebar-item">-->
          <!--            <a-->
          <!--              class="sidebar-link"-->
          <!--              routerLink="/dashboard/query"-->
          <!--              routerLinkActive="active"-->
          <!--              aria-expanded="false"-->
          <!--              aria-expanded="false"-->
          <!--            >-->
          <!--              <svg-->
          <!--                xmlns="http://www.w3.org/2000/svg"-->
          <!--                class="icon icon-tabler icon-tabler-brand-google-big-query"-->
          <!--                width="24"-->
          <!--                height="24"-->
          <!--                viewBox="0 0 24 24"-->
          <!--                stroke-width="2"-->
          <!--                stroke="#fff"-->
          <!--                fill="none"-->
          <!--                stroke-linecap="round"-->
          <!--                stroke-linejoin="round"-->
          <!--              >-->
          <!--                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>-->
          <!--                <path-->
          <!--                  d="M17.73 19.875a2.225 2.225 0 0 1 -1.948 1.125h-7.283a2.222 2.222 0 0 1 -1.947 -1.158l-4.272 -6.75a2.269 2.269 0 0 1 0 -2.184l4.272 -6.75a2.225 2.225 0 0 1 1.946 -1.158h7.285c.809 0 1.554 .443 1.947 1.158l3.98 6.75a2.33 2.33 0 0 1 0 2.25l-3.98 6.75v-.033z"-->
          <!--                ></path>-->
          <!--                <path-->
          <!--                  d="M11.5 11.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0"-->
          <!--                ></path>-->
          <!--                <path d="M14 14l2 2"></path>-->
          <!--              </svg>-->
          <!--            </a>-->
          <!--          </li>-->



          <!--          <li class="sidebar-item">-->
          <!--            <a-->
          <!--              class="sidebar-link"-->
          <!--              href="javascript:void(0);"-->
          <!--              routerLink="/dashboard/settings"-->
          <!--              routerLinkActive="active"-->
          <!--              aria-expanded="false"-->
          <!--            >-->
          <!--              <svg-->
          <!--                xmlns="http://www.w3.org/2000/svg"-->
          <!--                class="icon icon-tabler icon-tabler-settings"-->
          <!--                width="24"-->
          <!--                height="24"-->
          <!--                viewBox="0 0 24 24"-->
          <!--                stroke-width="2"-->
          <!--                stroke="#fff"-->
          <!--                fill="none"-->
          <!--                stroke-linecap="round"-->
          <!--                stroke-linejoin="round"-->
          <!--              >-->
          <!--                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>-->
          <!--                <path-->
          <!--                  d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"-->
          <!--                ></path>-->
          <!--                <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>-->
          <!--              </svg>-->
          <!--            </a>-->
          <!--          </li>-->

          <li class="sidebar-item">
            <a
              class="sidebar-link"
              href="javascript:void(0);"
              routerLink="/dashboard/tokenUsage"
              routerLinkActive="active"
              aria-expanded="false"
              alt="Token Usage"
              title = "Token Usage"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-history"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#fff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 8l0 4l2 2"></path>
                <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5"></path>
              </svg>
            </a>
          </li>
          <li class="sidebar-item">
            <a
              class="sidebar-link"
              href="javascript:void(0);"
              routerLink="/dashboard/subscription"
              routerLinkActive="active"
              aria-expanded="false"
              alt="Subscriptions"
              title="Subscriptions"
              style="color: white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-credit-card-filled"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M22 10v6a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-6h20zm-14.99 4h-.01a1 1 0 1 0 .01 2a1 1 0 0 0 0 -2zm5.99 0h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0 -2zm5 -10a4 4 0 0 1 4 4h-20a4 4 0 0 1 4 -4h12z"
                  stroke-width="0"
                  fill="currentColor"
                />
              </svg>
            </a>
          </li>

          <!-- <li class="sidebar-item">
            <a
                    class="sidebar-link"
                    routerLink="/dashboard/prompts"
                    routerLinkActive="active"
                    aria-expanded="false"
                    aria-expanded="false"
                    alt="Prompts"
                    title="Prompts"
            >
              <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-prompt"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="#fff"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 7l5 5l-5 5"></path>
                <path d="M13 17l6 0"></path>
              </svg>
            </a>
          </li> -->


          <!-- <li class="sidebar-item">
            <a
              class="sidebar-link"
              href="javascript:void(0);"
              routerLink="/dashboard/support"
              routerLinkActive="active"
              aria-expanded="false"
              style="color: white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-help"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M12 17l0 .01" />
                <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
              </svg>
            </a>
          </li> -->
        </ul>
      </nav>
    </div>
  </aside>

  <div class="body-wrapper">
    <app-header></app-header>
    <div class="sccrollable">
      <div class="container-fluid main-bg-wrapper mt-5 mb-3">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
    
  </div>
</div>
