<footer class="footer">
  <div class="container">
    <section class="d-flex flex-column flex-md-row justify-content-between p-4">
      <!-- Left -->
      <div class="me-5">
        <p class="mb-0">&copy; {{fullYear}} cognix.ai. All rights reserved.</p>
      </div>
      <!-- Left -->

      <!-- Right -->
      <div>
        <p class="mb-0">
          In case of issues or new feature requests, send an email to
          <a href="mailto:admin@cognix.ai" class="mail-link">admin@cognix.ai</a>
        </p>
      </div>
      <!-- Right -->
    </section>
  </div>
</footer>
