<div class="d-flex container row col-12 mt-3">
  <div class="second">
    <div class="row">
      <div class="col-6 d-flex justify-content-start align-items-center">
        <h1>New Issue Details</h1>
      </div>
    </div>
    <form
      #myForm="ngForm"
      (ngSubmit)="addIssue(myForm)"
      class="form-horizontal"
    >
      <div class="row mt-4">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label for="issueType">Issue Type:</mat-label>
          <mat-select
            name="issueType"
            id="issueType"
            [(ngModel)]="supportMessageModel.messageType"
            required
            #issueTypeTypeField="ngModel"
          >
            <mat-option value="Issue">Issue</mat-option>
            <mat-option value="Feature Request">Feature Request</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              issueTypeTypeField.invalid &&
              (issueTypeTypeField.dirty || issueTypeTypeField.touched)
            "
          >
            Message type is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row mt-3">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label for="issueDescription">Issue Description</mat-label>
          <textarea
            matInput
            id="issueDescription"
            name="text"
            cols="80"
            rows="5"
            ngModel
            required
            placeholder="Enter Description here"
            [(ngModel)]="supportMessageModel.text"
            #issueDescriptionField="ngModel"
          >
          </textarea>
          <mat-error
            *ngIf="
              issueDescriptionField.invalid &&
              (issueDescriptionField.dirty || issueDescriptionField.touched)
            "
          >
            Issue Description is required
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="button-container text-left d-flex flex-row-reverse"
        style="margin-top: 1em"
      >
        <button type="submit" class="btn add" [disabled]="myForm.invalid || supportMessageModel.text.trim() === ''">
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
